import styles from './Customers.module.css';
import DoubleSidedGradient from '../CommonComponents/DoubleSidedGradient/DoubleSidedGradient';
import TransparentButton from '../CommonComponents/TransparentButton/TransparentButton';
import BlueButton from '../CommonComponents/BlueButton/BlueButton';
import { useNavigate } from "react-router-dom";
import exclamation from "../../Images/Regular_size/exclamation.svg"
import mail2 from '../../Images/Regular_size/mail2.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CommercialOffer from './../CommonComponents/CommercialOffer/CommercialOffer';

function Customers() {
    const [isKP, setIsKP] = useState(false)
    let navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
       
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.justifier}>
            {/* {(!isKP) &&
                <div className={styles.kpWrapper} onClick={() => setIsKP(true)}>
                    <img src={mail2} width={"100%"} />
                </div>
            } */}
            {(isKP) && <CommercialOffer exit={() => setIsKP(false)} />}
            <div className={styles.order}>
                <DoubleSidedGradient />
                <div className={styles.orderTint} />
                <div className={styles.orderHeaderWrapper}>
                    <div className={styles.orderHeader}>
                        Информация для заказчиков
                    </div>
                </div>
                <div className={styles.orderSeparatorWrapper}>
                    <div className={styles.orderSeparator} />
                </div>
                <div className={styles.justifier}>
                    <div className={styles.orderText}>
                        Министерством просвещения РФ
                        <span style={{ color: "#0092DD", fontWeight: "bold" }}> был принят  Приказ № 804 </span>
                        от 21.12.2022 года «Об утверждении перечня средств обучения
                        и воспитания, необходимых для реализации образовательных программ начального общего, основного общего и среднего общего
                        образования, соответствующих современным условиям обучения, необходимого при оснащении общеобразовательных организаций в целях
                        реализации мероприятий по содействию созданию в субъектах Российской Федерации (исходя из прогнозируемой потребности) новых мест
                        в общеобразовательных организациях, критериев его формирования и требований к функциональному оснащению, а также норматива
                        стоимости оснащения одного места обучающегося указанными средствами обучения и воспитания».
                        <br/><br/>18 декабря признан утратившим силу пункт 1 приказа Министерства просвещения РФ от 6 сентября 2022 г. №804. Министерством просвещения РФ  был принят Приказ № 838 "Об утверждении перечня средств обучения и воспитания, соответствующих
                        современным условиям обучения, необходимых при оснащении общеобразовательных организаций в целях реализации мероприятий государственной программы Российской Федерации «Развитие
                        образования», направленных на содействие созданию (создание) в субъектах Российской Федерации новых (дополнительных) мест в общеобразовательных организациях, модернизацию инфраструктуры
                        общего образования, школьных систем образования, критериев его формирования и требований к функциональному оснащению общеобразовательных организаций.
                    </div>
                </div>
                <div className={styles.justifier}>
                    <div className={styles.orderArrowBox}>
                        <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.8612 0.420624C16.6667 0.155937 16.3605 0 16.0352 0H10.989L18.1944 10.9183C18.4267 11.2703 18.4267 11.7297 18.1944 12.0817L10.989 23H16.0352C16.3605 23 16.6667 22.8441 16.8612 22.5794L25 11.5L16.8612 0.420624Z" fill="#0092DD" />
                            <path d="M5.87217 0.420624C5.67773 0.155937 5.37152 0 5.0462 0H0L7.20537 10.9183C7.43767 11.2703 7.43767 11.7297 7.20537 12.0817L0 23H5.0462C5.37152 23 5.67773 22.8441 5.87217 22.5794L14.011 11.5L5.87217 0.420624Z" fill="#0092DD" />
                        </svg>
                        <div className={styles.orderArrowText}>
                            ООО "ТК "Полюс" регулярно принимает участие в проектах по оснащению детских садов и школ на всей территории РФ и странах СНГ.
                        </div>
                    </div>
                </div>
                <div className={styles.justifier}>
                    <div className={styles.orderLowerText}>
                        Вы можете прислать нам запрос на расчет стоимости проекта и получить помощь в составлении технического задания по поставке учебного
                        оборудования:
                        <span style={{ color: "#0092DD", fontWeight: "bold" }}> polus@tkpolus.ru </span>
                        <br /><br />
                        Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного
                        оборудования.
                    </div>
                </div>
                <svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.orderCross}>
                    <path d="M175.462 0.990365C175.645 -0.126267 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#C5E2F1" />
                </svg>
                <div className={styles.justifier}>
                    <div className={styles.orderButtons}>
                        <Link to="/pricelist.xlsx" target="_blank" download>
                            <div style={{ width: '200px', marginRight: '37px', marginTop: '3px', marginLeft: width > 915 ? '0' : '5%' }}>
                                <TransparentButton width={width > 915 ? 200 : width * 0.9} extension={width > 915 ? 220 : width * 0.9} text={"Прайс-лист"} />
                            </div>
                        </Link>
                        <div style={{ width: '304px', marginRight: '37px', marginTop: width > 915 ? '3px' : '15px', marginLeft: width > 915 ? '0' : '5%' }}>
                            <Link to="/838.pdf" target="_blank" download>
                                <TransparentButton width={width>700?214:width*0.9} extension={width>700?230.52:width*0.9} text={"Приказ №838"} />
                            </Link>
                        </div>
                        <div onClick={() => { navigate("/products"); window.scrollTo(0, 0) }} style={{ marginTop: width > 915 ? '3px' : '15px', marginLeft: width > 915 ? '0' : '5%' }}>
                            <BlueButton width={width > 915 ? 259 : width * 0.9} extension={width > 915 ? 280 : width * 0.9} text={"Перейти к товарам"} />
                        </div>
                    </div>
                </div>
                <div className={styles.orderBottomTint}>
                    <div className={styles.orderWarningContent}>
                        <LazyLoadImage
                            src={exclamation}
                            placeholderSrc={exclamation}
                            effect="blur"
                            className={styles.orderExclamation}
                        />
                        <div className={styles.orderWarningText}>
                            Внимание! Изображение товара на сайте может отличаться от полученного вами товара. Производитель оставляет за собой право изменять комплектацию и технические характеристики комплексов без предварительного уведомления, при этом функциональные и качественные показатели комплексов не ухудшаются. Информация о товаре носит справочный характер и не является публичной офертой, определяемой Статьей 437 ГК РФ
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Customers;
